exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .file-input_container_3QJO5 {\n    display: block;\n    position: relative;\n    padding: 1rem;\n    margin: 1rem 0;\n    text-align: center;\n    border: 4px dashed var(--ui-tertiary, hsla(215, 50%, 90%, 1));\n    border-radius: 5px;\n    cursor: pointer;\n    background: none;\n    width: 100%;\n} .file-input_container_3QJO5:focus-within {\n    border-color: hsla(0, 100%, 65%, 1);\n}\n", ""]);

// exports
exports.locals = {
	"container": "file-input_container_3QJO5"
};