exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .icon-button_container_Vz-T6 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    cursor: pointer;\n    font-size: 0.75rem;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: hsla(0, 100%, 65%, 1);\n    border-radius: 0.5rem;\n} .icon-button_container_Vz-T6 + .icon-button_container_Vz-T6 {\n    margin-top: 1.25rem;\n} .icon-button_title_3_47t {\n    margin-top: 0.5rem;\n    text-align: center;\n} .icon-button_disabled_14mfd {\n    opacity: 0.5;\n    pointer-events: none;\n} .icon-button_container_Vz-T6:active {\n    background-color: hsla(0, 100%, 65%, 0.15);\n}\n", ""]);

// exports
exports.locals = {
	"container": "icon-button_container_Vz-T6",
	"title": "icon-button_title_3_47t",
	"disabled": "icon-button_disabled_14mfd"
};