exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* this class must contain \"see-inside-button\" somewhere for the s3 dev tools to be able to reinject itself */ /* https://github.com/griffpatch/Scratch3-Dev-Tools/blob/0.2.4/inject3.js#L1804-L1807 */ .tw-see-inside_see-inside-button_1fSk6 {\n    box-shadow: 0 0 0 1px var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n} .tw-see-inside_see-inside-button-icon_UKAVj {\n    height: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"see-inside-button": "tw-see-inside_see-inside-button_1fSk6",
	"seeInsideButton": "tw-see-inside_see-inside-button_1fSk6",
	"see-inside-button-icon": "tw-see-inside_see-inside-button-icon_UKAVj",
	"seeInsideButtonIcon": "tw-see-inside_see-inside-button-icon_UKAVj"
};